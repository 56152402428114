import jQuery from "jquery"
var $ = jQuery

if(window.location.pathname == '/') {
  $(document).ready(function() {
    var userPosition = {};
    myPosition();
    
    $('.my-position-cta').on('click', function(){
      myPosition();
    });

    function myPosition() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          userPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        }
      )
    };

    function initMap() {
      var gmarkers = [];
      var infowindow = null;

      var styles = [
        {
          featureType: "administrative",
          elementType: "all",
          stylers: [
            { saturation: -99 }
          ]
        },{
          featureType: "landscape",
          elementType: "all",
          stylers: [
            { saturation: -99 }
          ]
        },{
          featureType: "poi",
          elementType: "all",
          stylers: [
            { saturation: -99 }
          ]
        },{
          featureType: "road",
          elementType: "all",
          stylers: [
            { saturation: -99 }
          ]
        },{
          featureType: "transit",
          elementType: "all",
          stylers: [
            { saturation: -99 }
          ]
        },{
          featureType: "water",
          elementType: "all",
          stylers: [
            { saturation: -99 }
          ]
        }
      ];
      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer();
      const map = new google.maps.Map(document.getElementById("map"), {
        zoom: 13,
        center: { lat: 45.0785348, lng: 7.6691219 },
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false,
        zoomControl: true,
        styles: styles
      });

      google.maps.event.addListener(map, 'click', function() {
        infowindow.close();
      });

      var locations = $('[data-venues]').data('venues');

      infowindow = new google.maps.InfoWindow({
        size: new google.maps.Size(300,150)
      });

      // Add the markers
      for (var i = 0; i < locations.length; i++) {
        var location = locations[i];
        var myLatLng = new google.maps.LatLng(location[1], location[2]);
        var marker = new google.maps.Marker({
            position: myLatLng,
            animation: google.maps.Animation.DROP,
            map: map,
            title: location[0],
            html: "<div style='color: black;'><b style='font-size: 1rem;'>"+location[0]+"</b><br />"+
                  "<p>"+location[3]+"</p>"+
                  "<b>Convenzioni:</b>"+
                  "<p>"+location[5]+"</p><br />"+
                  "<a href="+location[6]+" target='_blank'>Indicazioni</a><br /><br />"+
                  "<a href="+location[4]+" target='_blank'>Vai al sito</a></div>"
        });

        // Standard markers - if not using infobox
        google.maps.event.addListener(marker, "click", function () {
          infowindow.setContent(this.html);
          infowindow.open(map, this);
        });
        
        gmarkers.push(marker);
      }
      
      directionsRenderer.setMap(map);
      document.getElementById("submit").addEventListener("click", () => {
        calculateAndDisplayRoute(directionsService, directionsRenderer);
      });
    }
    
    function calculateAndDisplayRoute(directionsService, directionsRenderer) {
      const waypts = [];
      const checkboxArray = document.getElementsByClassName("waypoints");
      if(userPosition.length < 1) {
        myPosition();
      }

      for (let i = 0; i < checkboxArray.length; i++) {
        if (checkboxArray[i].checked) {
          waypts.push({
            location: JSON.parse(checkboxArray[i].value),
            stopover: false
          });
        }
      }

      directionsService
      .route({
        origin: userPosition,
        destination: waypts[waypts.length-1]['location'],
        waypoints: waypts.slice(0, waypts.length-1),
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.WALKING,
      })
      .then((response) => {
        directionsRenderer.setDirections(response);

        const route = response.routes[0];
        const summaryPanel = document.getElementById("directions-panel");

        summaryPanel.innerHTML = "";
        var totalDistance = 0;

        // For each route, display summary information.
        for (let i = 0; i < route.legs.length; i++) {
          const routeSegment = i + 1;

          // summaryPanel.innerHTML += "<b>Route Segment: " + routeSegment + "</b><br>";
          // summaryPanel.innerHTML += route.legs[i].start_address + " to ";
          // summaryPanel.innerHTML += route.legs[i].end_address + "<br>";
          // summaryPanel.innerHTML += route.legs[i].distance.text + "<br><br>";
          totalDistance = totalDistance + route.legs[i].distance.value
        }
        summaryPanel.innerHTML += "Distanza da percorrere: "+(totalDistance/1000).toFixed(2)+"km";
      })
      .catch((e) => window.alert("Directions request failed due to " + e));
    }

    window.initMap = initMap();

    // add window listener for GMaps
    // google.maps.event.addDomListener(window, 'load', initialize);

  });
}