// Entry point for the build script in your package.json

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "./extensions/turbolinks"
import * as ActiveStorage from "@rails/activestorage"
//import "./channels"

import jquery from "jquery"
window.$ = window.jQuery = jquery

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import '@fortawesome/fontawesome-free/js/all.min'
import 'bootstrap/dist/js/bootstrap.bundle.min'

import select2 from 'select2'
select2()

import 'slick-carousel'

import './packs/base_theme'
import './packs/direction_map'

$(document).ready(function() {
  $('.select2Map').select2()
})

$( document ).ready(function() {
  function getCookieValue(name) {
    let result = document.cookie.match("(^|[^;]+)\\s*" + name + "\\s*=\\s*([^;]+)")
    return result ? result.pop() : ""
  }

  function resetMenuPopup() {
    $('.venue-details').hide()
    $('.venue-details').removeClass(color)
    $('.venue-details .venue-details__contain').empty()
  }

  function populateMenuHeader(name, lastUp, site, menu_site) {
    $('.venue-details__contain').append('<h2>'+name+'</h2><h5>(Tap List del '+lastUp+')</h5>')
    if(site || menu_site){
      $('.venue-details__contain').append("<div class='venue-details__contain__footer'></div>")
    }
    if(site){
      $('.venue-details__contain__footer').append(
        "<span><a style='font-size: 1.5rem;' href="+site+" target='_blank'>"+
          "Vai al sito"+
        "</a></span>"
      )
    }
    if(menu_site){
      $('.venue-details__contain__footer').append(
        "<span><a style='font-size: 1.5rem;' href="+menu_site+" target='_blank'>"+
          "Vai al menu completo"+
        "</a></span>"
      )
    }
    $('.venue-details .venue-details__contain').append("<div class='venue-details__contain__loader'</div>")
  }

  function populateTapList(list) {
    if(Array.isArray(list)){ list = list.join() }
    $('.venue-details__contain').append("<div class='venue-details__contain__list'>"+list.replace(/\<\/p\>\,\<p\>/gm, '</p><p>')+"</div>")
  }

  function resetSearchLocations() {
    $('.pub_card').each(function(){
      $(this).show()
    })
  }

  function searchLocations() {
    let searchText = $('#search_form').val().toLowerCase()
    $('.search_loader').show()
    $('.pub_card').each(function(){
      if(searchText.length > 1){
        let that = this
        let pubDesc = $('[data-taplist]', this).data('taplist').toLowerCase()
        let pubName = $('[data-name]', this).data('name').toLowerCase()
        let context = pubDesc + pubName
        if(context.includes('contatami')){
          $(that).hide()
        }else if(!context.includes(searchText)){
          $(that).show()
          searchText.split(' ').forEach(text => {
            if(!context.includes(text)){
              $(that).hide()
            } else {
              $(that).show()
            }
          })
        }
      } else {
        $(this).show()
      }
    })
    setTimeout(function(){
      $('.search_loader').hide()
    }, 800)
  }

  //HOMEPAGE
  // events carousel
  $('.events_carousel').slick({
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    arrows: false,
    infinite: true
  });

  setTimeout(function(){
    let event_height = $('.events_carousel').height()
    $('.events_carousel__image').each(function(){
      $(this).height(event_height)
    })
  }, 200)

  // venues tap list popup
  var color =''
  $('.venue-details__close').on('click', function(){
    resetMenuPopup()
  })

  $('.venue-taplist-cta').on('click', function(){
    resetMenuPopup()
    color = $(this).data('color')
    $('.venue-details').addClass(color)
    $('.venue-details').show()
    let name = $(this).data('name')
    let site = $(this).data('venuesite')
    let lastUp = $(this).data('lastup')
    let menu_site = $(this).data('venuemenusite')
    let list = $(this).data('taplist')
    populateMenuHeader(name, lastUp, site, menu_site)
    populateTapList(list)
  });

  // beer search
  $('.search_button').on('click', function(){
    resetSearchLocations()
    searchLocations()
  });

  $('.search_form').on('keypress', function(event){
    if (event.key === "Enter") {
      resetSearchLocations()
      searchLocations()
    }
  });

  //HOMEPAGE - END

  (function ($) {
    "use strict"; // Start of use strict

    // // lang cookie
    // let siteLang = getCookieValue("tgm_lang");
    // let reverseLang = siteLang == 'it' ? 'en' : 'it';

    // $('.lang_switch a').text('');
    // $('.lang_switch').addClass(siteLang + '_lang');

    // $(document).on('click', '.lang_switch', function(e){
    //   document.cookie = "tgm_lang=" + reverseLang +"; path=/";

    //   location.reload();
    // });

    // block images height
    if (window.innerWidth > 992) {
      $('.img-event-container').each(function(){
        var h = $(this).parent('.row').height();
        $(this).height(h);
      });
    };

    setTimeout(function(){
      if(window.location.search == "?qrBeershop=1") {
        $('a.nav-link:contains("Beershop")').trigger('click')
      }
    }, 200)

    // menu tabs
    $('#menu-tab a').on('click', function (e) {
      e.preventDefault()
      $(this).tab('show')
    })

    //on_tap_logos
    $('.beerlogo').on('click', function (e) {
      let imgSrc = $('img', this).attr('src');
      $('.beerlogo_zoom img').attr('src', imgSrc)
      // $('.beerlogo_zoom').append("<div class='beerlogo_zoom__close'></div><img src="+imgSrc+">");
      $('.beerlogo_zoom').show(100);
    });

    $('.beerlogo_zoom__close').on('click', function () {
      $('.beerlogo_zoom').hide(100);
    });

    $('.nav-link').on('click', function () {
      $('.beerlogo_zoom').hide(100);
      $('.menu-alert').hide(100);
    });

    // Closes responsive menu when a scroll trigger link is clicked
    $('.js-scroll-trigger').click(function () {
      $('.navbar-collapse').collapse('hide');
    });

    // menu alert
    $('.menu-alert__close-cta').on('click', function(){
      $('.menu-alert').hide(100);
    })

    $('.nav-item.nav-link a').each(function(){
      $(this).addClass('js-scroll-trigger')
    });

    $('.menu-item .js-scroll-trigger').on('click', function(){
      $('.navbar-collapse').removeClass('show');
    });

    // Activate scrollspy to add active class to navbar items on scroll
    $('body').scrollspy({
      target: '#mainNav',
      offset: 100
    });

    // Collapse Navbar
    if($("#mainNav").length > 0) {
      var navbarCollapse = function () {
        if ($("#mainNav").offset().top > 100) {
          $("#mainNav").addClass("navbar-shrink");
        } else {
          $("#mainNav").removeClass("navbar-shrink");
        }
      };
      // Collapse now if page is not at top
      navbarCollapse();
      // Collapse the navbar when page is scrolled
      $(window).scroll(navbarCollapse);

      if (window.innerWidth <= 992) {
        $('body').data('device', 'ipad')
      };
      if (window.innerWidth <= 768) {
        $('body').data('device', 'iphone')
      };
    }

    $(window).on('resize', function () {
      var newHeight, newWidth, theHeight, theWidth;
      if ($('body').data('device').match(/(iphone|ipad)/)) {
        theWidth = window.screen.width;
        theHeight = window.screen.height;
      } else {
        theWidth = window.innerWidth;
        theHeight = window.innerHeight;
      }
      if ((theWidth / theHeight) >= (16 / 9)) {
        newWidth = theWidth;
        newHeight = theWidth / 9 * 16;
        return $('.video-venue-masthead--video__container').css({
          'width': newWidth,
          'height': newHeight
        });
      } else {
        newWidth = theHeight / 9 * 16;
        newHeight = theHeight;
        return $('.video-venue-masthead--video__container').css({
          'width': newWidth,
          'height': newHeight
        });
      }
    }).resize();

    // POINTS CARD SEARCH
    $('.points_card_nav_menu').on('click', function(){
      $('.menu-alert.points_card_popup_form').show()
    })

    $('.btn-beer-search-barcode').on('click', function(){
      searchBarcodeTrigger();
    });

    $('.search-input-barcode').on('keypress', function(event){
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if(keycode == '13'){ // when user press Enter
        searchBarcodeTrigger();
        document.activeElement.blur();
      };
    });

    function searchBarcodeTrigger() {
      $('.search-barcode-result').empty();
      var venueId = $('[data-venue]').data('venue')
      var searchString = $(".search-input-barcode").val();
      Rails.ajax({
        url: "/pc_barcode_search?vid="+venueId+"&q="+searchString,
        type: "get",
        success: function(data) {
          let points = data['points'] == null ? 'Tessera Errata!' : ("Punti: " + data['points'])
          $('.search-barcode-result').append(points);
        },
        error: function(data) {}
      });
    };
    // END - POINTS CARD SEARCH

    // SEARCH
    $('.btn-beer-search-desk').on('click', function(){
      searchTrigger('desk');
    });

    $('.search-input-desk').on('keypress', function(event){
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if(keycode == '13'){ // when user press Enter
        searchTrigger('desk');
      };
    });

    $('.btn-beer-search-mobile').on('click', function(){
      searchTrigger('mobile');
    });

    $('.search-input-mobile').on('keypress', function(event){
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if(keycode == '13'){ // when user press Enter
        searchTrigger('mobile');
        document.activeElement.blur();
      };
    });

    function searchTrigger(device) {
      var venueId = $('[data-venue]').data('venue')
      var searchString = $(".search-input-"+device).val();
      Rails.ajax({
        url: "/beer_search_routine?vid="+venueId+"&q="+searchString,
        type: "get",
        success: function(data) {
          $('.row-beershop').remove();
          $('.hr-beershop').remove();
          updateBeershopRow(data, device);
        },
        error: function(data) {}
      });
    };

    function updateBeershopRow(data, device) {
      $.each(data['collection'], function(_index, value) {
        var new_product = ''
        if(value['new'] == true){
          new_product = "<span class='tab-ribbon'>NEW</span>"
        }
        var image_html_segment = ''
        if(value['image'].length > 0 ){
          image_html_segment = "<div class='col-xl-1 col-lg-1 col-sm-2 col-4'>\
                                    <div class='beerlogo'>"+new_product+"<img src='"+value['image']+"'></div>\
                                  </div>"
        }
        let html_element = "\
          <div class='row mb-3 row-beershop'>\
            "+image_html_segment+"\
            <div class='col-xl-7 col-lg-6 col-sm-6 col-8 px-2'>\
              <p class='menu-row-title'>"+value['fullname']+"\
              <div class='small mb-1' style='display: block;'>"+value['caption_one']+"</div>\
              <div class='small' style='display: block;'>"+value['caption_two']+"</div>\
            </div>\
            <div class='col-xl-4 col-lg-5 col-sm-4 text-end'>";
        if(value['finished']) {
          let siteLang = getCookieValue("tgm_lang");
          html_element = html_element + "<small class='flag-finished-"+siteLang+"'></small>";
        }
        html_element = html_element + "<strong>"+value['price']+"</strong></div>\
          </div>\
          <hr class='hr-beershop' style='border-color: rgba(255, 255, 255, 0.3); border-style: dashed'>\
        ";
        $("#beershop-"+device).append(html_element);
      });
    };
    // END - SEARCH

  })(jQuery); // End of use strict
})
